import { modules, perms } from "../../api/codes";
import { items } from "../../data/routes";
import B2BStore from "../../pages/b2b_store/b2b_store";
import { B2BProfileList } from "../../pages/b2b_store/b2b_verification/profile_list";
import { VerifyB2BProfile } from "../../pages/b2b_store/b2b_verification/verify_profile";
import B2BAddCredit from "../../pages/b2b_store/eligible_users/add_credit";
import { B2BEligibleUsers } from "../../pages/b2b_store/eligible_users/eligible_users";
import { B2BTransactionHistory } from "../../pages/b2b_store/eligible_users/transaction_history";
import FirstCategory from "../../pages/masters/first_category/first_category";
import NewFirstCategory from "../../pages/masters/first_category/new_first_category";
import NewSecondCategory from "../../pages/masters/second_category/new_second_category";
import SecondCategory from "../../pages/masters/second_category/second_category";
import NewThirdCategory from "../../pages/masters/third_category/new_third_category";
import ThirdCategory from "../../pages/masters/third_category/third_category";
import NewProduct from "../../pages/products/product_list/new_product";
import ProductList from "../../pages/products/product_list/product_list";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteB2B = [
  {
    path: "/b2b_store",
    content: (
      <ProtectedElement
        module={items
          .find((item) => item.module === modules.GlobalCards)
          .childrens.map((ch) => ch.module)}
        perm={perms.viewList}
        page
      >
        <B2BStore />
      </ProtectedElement>
    ),
  },
  {
    path: "/b2b_store/b2b_verification",
    content: (
      <ProtectedElement
        module={modules.B2BVerification}
        perm={perms.viewList}
        page
      >
        <B2BProfileList />
      </ProtectedElement>
    ),
  },
  {
    path: "/b2b_store/b2b_verification/verify/:id",
    content: (
      <ProtectedElement module={modules.B2BVerification} perm={perms.edit} page>
        <VerifyB2BProfile />
      </ProtectedElement>
    ),
  },
  {
    path: "/b2b_store/eligible_users",
    content: (
      <ProtectedElement
        module={modules.B2BEligibleUsers}
        perm={perms.viewList}
        page
      >
        <B2BEligibleUsers />
      </ProtectedElement>
    ),
  },
  {
    path: "/b2b_store/eligible_users/add_credit/:id",
    content: (
      <ProtectedElement module={modules.B2BEligibleUsers} perm={perms.add} page>
        <B2BAddCredit />
      </ProtectedElement>
    ),
  },
  {
    path: "/b2b_store/eligible_users/transaction_history/:id",
    content: (
      <ProtectedElement
        module={modules.B2BEligibleUsers}
        perm={perms.view}
        page
      >
        <B2BTransactionHistory />
      </ProtectedElement>
    ),
  },
  ...makeRoute("products", ProductList, NewProduct, modules.GlobalProduct),
  ...makeRoute(
    "first_category",
    FirstCategory,
    NewFirstCategory,
    modules.GlobalCategoryOne
  ),
  ...makeRoute(
    "second_category",
    SecondCategory,
    NewSecondCategory,
    modules.GlobalCategoryTwo
  ),
  ...makeRoute(
    "third_category",
    ThirdCategory,
    NewThirdCategory,
    modules.GlobalCategoryThree
  ),
];

function makeRoute(route, Dashboard, Form, module, props = {}) {
  return [
    {
      path: `/b2b_store/${route}`,
      content: (
        <ProtectedElement module={module} perm={perms.viewList} page>
          <Dashboard isB2B {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/b2b_store/${route}/new`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isB2B {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/b2b_store/${route}/edit/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.edit} page>
          <Form edit isB2B {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/b2b_store/${route}/view/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.view} page>
          <Form view isB2B {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/b2b_store/${route}/copy/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isB2B {...props} />
        </ProtectedElement>
      ),
    },
  ];
}
