import { Header, Table } from "semantic-ui-react";
import Pagination from "../../../components/Pagination/Pagination.js";
import { useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import MoreInfoModal from "./more_info_modal";
import { api } from "../../../api/api";
import axios from "axios";
import moment from "moment";
import unknownError from "../../../utils/unknownError.js";
// import SortByDate from "../../../components/SortByDate/SortByDate.js";
import GenerateBill from "./generate_bill.js";
import { na, na2, namt } from "../../../utils/na.js";
import CancelModal from "./cancel_modal.js";
import ChangeOrderStatus from "./change_order_status.js";
import ChangePickAddModal from "./change_pick_add.js";
import {
  userAddressFormatter,
  userNameFormatter,
} from "../../../utils/addresssFormatter.js";
import RefundModal from "./refund_modal.js";
import CancelBill from "./cancel_bill.js";
import { modules, perms } from "../../../api/codes.js";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement.js";
import { delivery_partner_url, order_list_url } from "../../../api/urls.js";
import { useFilter } from "../../../components/filter/filter.js";
import { InputCheckBox } from "../../../components/Inputs/Inputs.js";
import { Input } from "../../../components/input.js";
import { useFetch } from "../../../utils/useFetch.js";
import { useSortBy } from "../../../components/sortBy/sortBy.js";
import { z } from "zod";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage.js";
import { TextChip } from "../../../components/TextChip/TextChip.js";
// import { InputDate } from "../../../components/Inputs/Inputs.js";

const module = modules.Order;

const OrderList = () => {
  const [data, setData] = useState();
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState();
  const [refetcher, setRefetcher] = useState(false);

  const filter = useFilter({
    render: ({ methods }) => <FilterUI methods={methods} />,
    schema: z
      .object({
        created__range: z.any(),
      })
      .superRefine((values, ctx) => {
        if (values.created__range?.[0] && !values.created__range?.[1]) {
          ctx.addIssue({
            message: "Both dates are required",
            path: ["created__range", 1],
          });
        }
        if (values.created__range?.[1] && !values.created__range?.[0]) {
          ctx.addIssue({
            message: "Both dates are required",
            path: ["created__range", 0],
          });
        }
      }),
  });
  const sortBy = useSortBy({
    options: [
      { name: "recently updated", value: "-updated" },
      { name: "recently added", value: "-created" },
    ],
    defaultValue: "-updated",
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      try {
        setData();
        const res = await api.get(
          order_list_url +
            "?limit=" +
            limit +
            "&offset=" +
            offset +
            // "&status_code=" +
            // getStatusCode(activeTab, subActiveTab) +
            `&col=${sortBy.value}` +
            `&filter=${JSON.stringify(filter.obj)}`,
          { cancelToken: source.token, headers: { module: module } }
        );
        setData(res.data);
        setCount(res.data.count);
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
  }, [limit, offset, setData, refetcher, filter.obj, sortBy.value]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return (
    <>
      <div id="heading">
        <Header>Order List</Header>
        <div className="right">
          {sortBy.SortByButton}
          {filter.FilterButton}
          {/* <InputDate */}
          {/*   value={dateRangeStart} */}
          {/*   onChange={(v) => setDateRangeStart(v)} */}
          {/*   error={ */}
          {/*     dateRangeEnd && !dateRangeStart ? "Please fill both dates" : "" */}
          {/*   } */}
          {/* /> */}
          {/* <label className="input-label">To</label> */}
          {/* <InputDate */}
          {/*   value={dateRangeEnd} */}
          {/*   onChange={(v) => setDateRangeEnd(v)} */}
          {/* /> */}
          {/* <Input */}
          {/*   type="text" */}
          {/*   placeholder="Search" */}
          {/*   value={search} */}
          {/*   onChange={(e) => setSearch(e.target.value)} */}
          {/*   icon={<Icon name="search" link id="search-icon" />} */}
          {/* /> */}
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "10px",
          flexShrink: 0,
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 0,
            overflow: "auto",
          }}
        >
          <Tabs
            active={activeTab}
            options={tabs}
            setActive={setActiveTab}
            count={tabs[activeTab]?.subTabs === null ? countForTab : undefined}
            setCount={setCountForTab}
          />
          {tabs[activeTab].subTabs && (
            <SubTabs
              active={subActiveTab}
              options={tabs[activeTab].subTabs}
              setActive={setSubActiveTab}
              count={countForTab}
              setCount={setCountForTab}
            />
          )}
        </div>
      </div> */}
      {data ? (
        <>
          {data.results.length > 0 ? (
            <div className="table-wrapper table-wrapper-styles">
              <Table
                className="table-component no-wrap"
                size="small"
                unstackable
              >
                <Table.Body>
                  {data.results.map((d, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell
                        verticalAlign="top"
                        style={{ position: "relative" }}
                      >
                        {[
                          "OS_ ORDERPROCESSED_52",
                          // "OS_SHPD_002",
                          // "OS_OUTFRDELIV_53",
                        ].indexOf(d.status_code) > -1 &&
                          !(
                            (d?.shipping_label &&
                              !(isReturn(d) || isReplace(d))) ||
                            ((isReturn(d) || isReplace(d)) &&
                              d?.shipping_return_label)
                          ) && (
                            <div
                              className="active-red"
                              style={{
                                fontSize: "11px",
                                position: "absolute",
                                borderRadius: "0px 0px 10px 0px",
                                color: "white",
                                padding: "0px 10px",
                                top: 0,
                                left: 0,
                                width: "auto",
                                zIndex: 10,
                                backgroundColor: "#e02200",
                              }}
                            >
                              Airwaybill Cancelled
                            </div>
                          )}
                        {[
                          "OS_ ORDERPROCESSED_52",
                          "OS_SHPD_002",
                          "OS_OUTFRDELIV_53",
                        ].indexOf(d.status_code) > -1 &&
                          (isReturn(d) || isReplace(d)) && (
                            <div
                              className="active-green"
                              style={{
                                fontSize: "11px",
                                position: "absolute",
                                borderRadius: "0px 0px 10px 0px",
                                color: "white",
                                padding: "0px 10px",
                                top: 0,
                                left: 0,
                                width: "auto",
                                zIndex: 9,
                                backgroundColor: "#76d355",
                              }}
                            >
                              {isReturn(d) ? "Return" : "Replace"}
                            </div>
                          )}
                        <div className="flex-column">
                          <div style={{ padding: "5px" }} />
                          <DashboardImage
                            src={d.tagon_product_image}
                            height="100px"
                            width="100px"
                          />
                          <div style={{ padding: "5px" }} />
                          <MoreInfoModal data={d} statusCode={d.status_code} />
                        </div>
                      </Table.Cell>
                      <Table.Cell verticalAlign="top">
                        <div className="flex-column wrap">
                          <div className="wrap">
                            <b>Order id : </b>
                            {na2(
                              d.order_id,
                              <span style={{ color: "#AD00FF" }}>
                                {d.order_id}
                              </span>
                            )}
                          </div>
                          <div>
                            <b>Order date : </b>
                            {na2(
                              d.created,
                              moment(d.created).format("DD-MM-YYYY")
                            )}
                          </div>
                          <div>
                            <b>Time : </b>
                            {na2(
                              d.created,
                              moment(d.created).format("hh:mm A")
                            )}
                          </div>
                          <div>
                            <b>Buyer name : </b>
                            {na(d?.customer_name)}
                          </div>
                          <div>
                            <b>Seller name : </b>
                            {na(d?.prod_json?.seller_name)}
                          </div>
                          <div className="wrap">
                            <b>Payment mode : </b>
                            {na(d.payment_method)}
                          </div>
                          <div>
                            <b>Business type : </b>
                            {d.is_company ? "B2B" : "B2C"}
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell verticalAlign="top">
                        <div className="flex-column">
                          <div className="wrap">
                            <b>Product title : </b>
                            {na(d?.prod_json?.title)}
                          </div>
                          <div className="wrap">
                            <b>Product description : </b>
                            {na(d?.prod_json?.short_description)}
                          </div>
                          <div>
                            <b>Size : </b>
                            {na(d.size)}
                          </div>
                          <div>
                            <b>Color : </b>
                            {na(d.color)}
                          </div>
                          <div>
                            <b>Quantity : </b>
                            {na(d.quantity)}
                          </div>
                          <div>
                            <b>Selling price : </b>
                            {namt(
                              d.subtotal_after_tax_amt,
                              data.currency_symbol
                            )}
                          </div>
                          <div>
                            <b>Paid amount : </b>
                            {namt(
                              d.total_amount_paid_amt,
                              data.currency_symbol
                            )}
                          </div>
                          <div>
                            <b>Total order value : </b>
                            {namt(
                              d.total_amount_paid_amt,
                              data.currency_symbol
                            )}
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell verticalAlign="top">
                        <div className="flex-column">
                          <div>
                            <b>Shipping address</b>
                          </div>
                          {d?.shipping_address?.name && (
                            <div>{userNameFormatter(d?.shipping_address)}</div>
                          )}
                          <div className="wrap">
                            {userAddressFormatter(d.shipping_address)}
                          </div>
                          {d?.shipping_address?.primary_mobile && (
                            <div>
                              Mobile No : +{d?.shipping_address?.primary_mobile}
                            </div>
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell verticalAlign="top">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <TextChip
                            text={d.action_name}
                            color={"#" + d.icon_color_code}
                          />
                          {/* <div
                            className="active-green no-wrap"
                            style={{
                              backgroundColor: "#" + d.icon_color_code + "f2",
                              width: "100%",
                              padding: "5px 20px",
                              letterSpacing: "0.5px",
                            }}
                          >
                            {na(d.action_name)}
                          </div> */}
                        </div>
                      </Table.Cell>
                      <Table.Cell verticalAlign="top">
                        <ActionDropdown
                          options={(closer) => {
                            const actions = getActions(
                              d.status_code,
                              d,
                              refetch
                            );
                            return (
                              <>
                                {actions
                                  ? actions.map((a, index) =>
                                      a ? (
                                        <div
                                          key={index}
                                          className="option"
                                          onClick={() => {
                                            closer();
                                          }}
                                        >
                                          {a}
                                        </div>
                                      ) : null
                                    )
                                  : null}
                              </>
                            );
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <div style={{ margin: "auto" }}>No Orders</div>
          )}
        </>
      ) : (
        <Loading />
      )}
      {count ? (
        <Pagination
          pages={[5, 10, 15, 25, 50]}
          count={count}
          setLimit={setLimit}
          setOffset={setOffset}
        />
      ) : null}
    </>
  );
};

const getActions = (code, d, refetch) => {
  if (code === "OS_ ORDERCNFRMD_47") {
    return [
      <ProtectedElement
        module={module}
        perm={perms.generateAirwaybillOrderConfirm}
      >
        <GenerateBill data={d} refetch={refetch} statusCode={code} />
      </ProtectedElement>,
      <ProtectedElement module={module} perm={perms.cancelProductOrderConfirm}>
        <CancelModal
          data={d}
          actionName="Cancel Product"
          actionPath="cancelproduct"
          refetch={refetch}
        />
      </ProtectedElement>,
      <ProtectedElement module={module} perm={perms.orderDenyOrderConfirm}>
        <CancelModal
          data={d}
          actionName="Order Deny"
          actionPath="order_deny"
          refetch={refetch}
        />
      </ProtectedElement>,
      <ProtectedElement module={module} perm={perms.changePickUpOrderConfirm}>
        <ChangePickAddModal
          data={d}
          actionName="Change Pick-Up Location"
          actionPath="pickup_address"
          refetch={refetch}
        />
      </ProtectedElement>,
    ];
  }
  if (code === "OS_ ORDERPROCESSED_52") {
    return [
      (d?.shipping_label && !(isReturn(d) || isReplace(d))) ||
      ((isReturn(d) || isReplace(d)) && d?.shipping_return_label) ? (
        <ProtectedElement
          module={module}
          perm={perms.cancelAirwayBillOrderProcessed}
        >
          <CancelBill
            data={d}
            refetch={refetch}
            statusCode={code}
            actionName="Cancel Airway Bill"
          />
        </ProtectedElement>
      ) : null,
      !(
        (d?.shipping_label && !(isReturn(d) || isReplace(d))) ||
        ((isReturn(d) || isReplace(d)) && d?.shipping_return_label)
      ) ? (
        <ProtectedElement module={module} perm={perms.orderDenyOrderProcessed}>
          <CancelModal
            data={d}
            actionName="Order Deny"
            actionPath="order_deny"
            refetch={refetch}
          />
        </ProtectedElement>
      ) : null,
      !(
        (d?.shipping_label && !(isReturn(d) || isReplace(d))) ||
        ((isReturn(d) || isReplace(d)) && d?.shipping_return_label)
      ) ? (
        <ProtectedElement
          module={module}
          perm={perms.regenerateAirwaybillOrderProcessed}
        >
          <GenerateBill
            data={d}
            refetch={refetch}
            statusCode={code}
            actionName="Regenerate Airway Bill"
            regenerate={true}
          />
        </ProtectedElement>
      ) : null,
      !(
        (d?.shipping_label && !(isReturn(d) || isReplace(d))) ||
        ((isReturn(d) || isReplace(d)) && d?.shipping_return_label)
      ) ? (
        <ProtectedElement
          module={module}
          perm={perms.changePickUpOrderProcessed}
        >
          <ChangePickAddModal
            data={d}
            actionName="Change Pick-Up Location"
            actionPath="pickup_address"
            refetch={refetch}
          />
        </ProtectedElement>
      ) : null,
    ];
  }
  if (code === "OS_DLVRD_003") {
    return [
      d?.is_returnable && (
        <ProtectedElement module={module} perm={perms.returnProductDelivered}>
          <CancelModal
            data={d}
            actionName="Return Product"
            actionPath="returnproduct"
            refetch={refetch}
          />
        </ProtectedElement>
      ),
      d?.no_replace > 0 && d?.is_replacable && (
        <ProtectedElement module={module} perm={perms.replaceProductDelivered}>
          <CancelModal
            data={d}
            actionName="Replace Product"
            actionPath="replaceproduct"
            refetch={refetch}
          />
        </ProtectedElement>
      ),
    ];
  }
  if (code === "OS_CNCLDRQSTD_032") {
    return [
      <ProtectedElement module={module} perm={perms.acceptCancelRequested}>
        <ChangeOrderStatus
          data={d}
          actionName="Accept"
          actionRoute="status_change"
          refetch={refetch}
          statusCode="OS_CNCLD_029"
        />
      </ProtectedElement>,
      <ProtectedElement
        module={module}
        perm={perms.generateAirwayBillCancelRequested}
      >
        <GenerateBill data={d} refetch={refetch} statusCode={code} />
      </ProtectedElement>,
    ];
  }
  if (code === "OS_CNCLD_029") {
    return [
      d.is_refundable && d.paid_status !== "UNPAID" && (
        <ProtectedElement module={module} perm={perms.initialRefundCancelled}>
          <RefundModal
            actionName="Initiate refund"
            actionPath="Refund"
            refetch={refetch}
            data={d}
          />
        </ProtectedElement>
      ),
    ];
  }
  if (code === "OS_RTRNRQSTD_015") {
    return [
      <ProtectedElement module={module} perm={perms.initialRefundCancelled}>
        <GenerateBill data={d} refetch={refetch} statusCode={code} />
      </ProtectedElement>,
      <ProtectedElement module={module} perm={perms.denyReturnRequested}>
        <ChangeOrderStatus
          data={d}
          actionName="Deny"
          actionRoute="status_change"
          refetch={refetch}
          statusCode="OS_RTRNDEND_021"
        />
      </ProtectedElement>,
    ];
  }
  if (code === "OS_RTRND_018") {
    return [
      d.is_refundable && d.paid_status !== "UNPAID" && (
        <ProtectedElement module={module} perm={perms.initialRefundReturned}>
          <RefundModal
            actionName="Initiate refund"
            actionPath="Refund"
            refetch={refetch}
            data={d}
          />
        </ProtectedElement>
      ),
    ];
  }
  if (code === "OS_RPLCRQSTD_005") {
    return [
      <ProtectedElement
        module={module}
        perm={perms.generateAirwayBillReplaceRequested}
      >
        <GenerateBill data={d} refetch={refetch} statusCode={code} />
      </ProtectedElement>,
      <ProtectedElement module={module} perm={perms.denyReplaceRequested}>
        <ChangeOrderStatus
          data={d}
          actionName="Deny"
          actionRoute="status_change"
          refetch={refetch}
          statusCode="OS_RPLCDND_006"
        />
      </ProtectedElement>,
    ];
  }
  if (code === "OS_ORDRDEND_53") {
    return [
      d.is_refundable && d.paid_status !== "UNPAID" && (
        <ProtectedElement module={module} perm={perms.initialRefundOrderDenied}>
          <RefundModal
            actionName="Initiate refund"
            actionPath="Refund"
            refetch={refetch}
            data={d}
          />
        </ProtectedElement>
      ),
    ];
  }
  if (code === "OS_ORDRFLD_004") {
    return [
      (d?.wallet || d?.points || d?.promo_coupons) && (
        <ProtectedElement module={module} perm={perms.initialRefundOrderFailed}>
          <RefundModal
            actionName="Initiate refund"
            actionPath="Refund"
            refetch={refetch}
            data={d}
            isFailed={true}
          />
        </ProtectedElement>
      ),
    ];
  }
};

function FilterUI({ methods }) {
  const statuses = [
    {
      name: "Order Confirmed",
      value: "OS_ ORDERCNFRMD_47",
    },
    {
      name: "Waiting for Pickup",
      value: "OS_ ORDERPROCESSED_52",
    },
    {
      name: "Shipped",
      value: "OS_SHPD_002",
    },
    {
      name: "Out for Delivery",
      value: "OS_OUTFRDELIV_53",
    },
    {
      name: "Delivered",
      value: "OS_DLVRD_003",
    },
    {
      name: "Cancel Request",
      value: "OS_CNCLDRQSTD_032",
    },
    {
      name: "Cancelled",
      value: "OS_CNCLD_029",
    },
    {
      name: "Return Request",
      value: "OS_RTRNRQSTD_015",
    },
    {
      name: "Returned",
      value: "OS_RTRND_018",
    },
    {
      name: "Return Denied",
      value: "OS_RTRNDEND_021",
    },
    {
      name: "Replace Request",
      value: "OS_RPLCRQSTD_005",
    },
    {
      name: "Replaced",
      value: "OS_RPLCD_010",
    },
    {
      name: "Replace Denied",
      value: "OS_RPLCDND_006",
    },
    {
      name: "Refunded",
      value: "OS_RFNDD_020",
    },
    {
      name: "Order Pending",
      value: "OS_PNDG_027",
    },
    {
      name: "Order Denied",
      value: "OS_ORDRDEND_53",
    },
    {
      name: "Failed",
      value: "OS_ORDRFLD_004",
    },
  ];

  const statusCodeKey = "status__code__in";
  const fields = methods.watch(statusCodeKey);
  const add = (value) => {
    const newData = [...(fields || [])];
    newData.push(value);
    methods.setValue(statusCodeKey, newData);
  };
  const remove = (value) => {
    const newData = [...(fields || [])];
    methods.setValue(
      statusCodeKey,
      newData.filter((v) => v !== value)
    );
  };

  const deliveryPartnerData = useFetch(delivery_partner_url);

  return (
    <>
      <div className="form-grid" style={{ width: "600px" }}>
        <Input.Date
          methods={methods}
          label="Date range start"
          placeholder="date from"
          name="created__range.0"
          format="YYYY-MM-DD"
        />
        <Input.Date
          methods={methods}
          label="Date range end"
          placeholder="date to"
          name="created__range.1"
          format="YYYY-MM-DD"
        />
        <Input.Text
          methods={methods}
          label="Order ID"
          placeholder="Order ID"
          name="order_id__icontains"
        />
        <Input.Select
          methods={methods}
          label="Delivery partner"
          placeholder="Delivery partner"
          name="dlvr_prtnr_data"
          loading={deliveryPartnerData.loading}
          options={deliveryPartnerData.data}
        />
      </div>
      <div className="p-10" />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "10px",
          columnGap: "20px",
        }}
      >
        {statuses.map((st, index) => (
          <InputCheckBox
            label={st.name}
            key={index}
            value={fields?.find((field) => field === st.value) !== undefined}
            onChange={(v) => {
              if (v) {
                add(st.value);
              } else {
                remove(st.value);
              }
            }}
          />
        ))}
      </div>
      <div className="p-10" />
    </>
  );
}

export const isReturn = (data) => {
  if (data?.order_logs?.find((log) => log.action_code === "OS_RTRNRQSTD_015"))
    return true;
  return false;
};

export const isReplace = (data) => {
  if (data?.order_logs?.find((log) => log.action_code === "OS_RPLCRQSTD_005"))
    return true;
  return false;
};

export default OrderList;
