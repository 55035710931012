import { tagon_customer_url } from "../../../api/urls";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import BackButton from "../../../components/BackButton/BackButton";
import { Header, Icon, Input } from "semantic-ui-react";
import { useState } from "react";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { TextChip } from "../../../components/TextChip/TextChip";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { useModal } from "../../../components/Modaler";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { InputNumber } from "../../../components/Inputs/Inputs";

export function B2BTransactionHistory() {
  const params = useParams();
  const backUrl = "/b2b_store/eligible_users";
  const apiUrl = `${tagon_customer_url}${params.id}/extenddue/`;
  const title = "Unpaid Dues";
  const module = modules.B2BEligibleUsers;
  const modaler = useModal();
  const { data, pagination } = useFetchWithPagination(apiUrl);
  const columns = [
    {
      headerName: "Trasaction Date",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Transaction ID",
      field: "paid_order_id",
    },
    {
      headerName: "Balance Credit",
      field: "balance",
      renderCell: (params) => `${params.row.currency_sym}${params.value}`,
    },
    {
      headerName: "Used Credit",
      field: "amount",
      renderCell: (params) => `${params.row.currency_sym}${params.value}`,
    },
    {
      headerName: "Due Date",
      field: "duedate",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Status",
      field: "is_paid",
      renderCell: (params) => (
        <TextChip
          color={params?.value === "PAID" ? "#03A326" : "#b91c1c"}
          text={params?.value === "PAID" ? "Paid" : "Unpaid"}
        />
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          options={(closer) => {
            return (
              <>
                <ProtectedElement module={module} perm={perms.add}>
                  <div
                    className="option"
                    onClick={() => {
                      modaler.opener(
                        <ExtendDueDate
                          d={params.row}
                          refetch={data.refetch}
                          closer={modaler.closer}
                        />,
                        false
                      );
                    }}
                  >
                    <div className="purple-title">Extend due date</div>
                  </div>
                </ProtectedElement>
              </>
            );
          }}
        />
      ),
    },
  ];

  const { search, setSearch } = useState("");
  return (
    <>
      <BackButton href={backUrl} />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      <TableComponent
        columns={columns}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </>
  );
}

function ExtendDueDate({ d, refetch, closer }) {
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState("");

  const toast = useToast();
  return (
    <div style={{ padding: "40px", width: "500px", maxWidth: "100%" }}>
      <InputNumber
        disabled
        label="Enter credit amount"
        placeholder="Enter credit amount"
        value={d.balance}
      />
      <div style={{ padding: "5px" }} />
      <InputNumber
        label="Next credit settlement period (In Days)"
        placeholder="Next credit settlement period (In Days)"
        required
        value={days}
        onChange={(v) => setDays(v)}
      />
      <div style={{ padding: "10px" }} />
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <button
          className="btn-red"
          onClick={() => {
            closer();
          }}
        >
          Cancel
        </button>
        <button
          className="btn"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              await api.post(tagon_customer_url + d?.user_id + "/extenddue/", {
                id: d.id,
                days: days,
              });
              closer();
              refetch();
              toast.open("Status change", "success");
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setLoading(false);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}
