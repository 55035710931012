export const modules = {
  Order: "Order",

  Product: "Product",

  Advertisement: "Advertisement",

  Coupon: "Coupon",

  MasterCards: "MasterCards",
  CategoryChart: "CategoryChart",
  OrderStatus: "OrderStatus",
  FirstCategory: "Category First",
  SecondCategory: "Category Second",
  ThirdCategory: "Category Third",
  ProductSize: "ProductSize",
  ProductColor: "ProductColor",
  Brand: "Brand",
  PropertyType: "PropertyType",
  PropertyValue: "PropertyValue",
  HsnCode: "HsnCode",
  HsnPercent: "HsnPercent",
  SacCode: "SacCode",
  SacPercent: "SacPercent",
  ChargeName: "ChargeName",
  Reel: "Reel",
  ProductQuestion: "ProductQuestion",
  ProductInfo: "ProductInfo",
  SortList: "SortList",
  LogisticPartner: "LogisticPartner",
  Continent: "Continent",
  Country: "Country",
  Region: "Region",
  State: "State",
  District: "District",
  Taluka: "Taluka",
  City: "City",
  Village: "Village",
  Pincode: "Pincode",
  Area: "Area",
  AnnualTurnover: "AnnualTurnover",
  BusinessType: "BusinessType",
  OrganisationType: "OrganisationType",
  FacilityType: "FacilityType",
  DocumentType: "DocumentType",
  CertificateType: "CertificateType",
  Role: "Roles",
  SizeChartColumn: "SizeChartColumn",
  MartialStatus: "MartialStatus",
  Title: "Title",
  PaymentGateway: "PaymentGateway",
  Notification: "Notification",
  WidgetTitle: "WidgetTitle",
  Configuration: "Configuration",
  Branch: "Branch",
  Department: "Department",
  Designation: "Designation",
  Granding: "Granding",
  Profile: "Profile",
  B2BVerificationFeilds: "B2BVerificationFields",

  GlobalCards: "GlobalCards",
  GlobalProduct: "GlobalProduct",
  GlobalSeller: "GlobalSeller",
  GlobalLuxuryBrand: "GlobalLuxuryBrand",
  GlobalPremiumBrand: "GlobalPremiumBrand",
  GlobalCategoryOne: "GlobalCategoryOne",
  GlobalCategoryTwo: "GlobalCategoryTwo",
  GlobalCategoryThree: "GlobalCategoryThree",

  B2BCards: "B2BCards",
  B2BProduct: "B2BProduct",
  B2BCategoryOne: "B2BCategoryOne",
  B2BCategoryTwo: "B2BCategoryTwo",
  B2BCategoryThree: "B2BCategoryThree",
  B2BVerification: "B2BDocs",
  B2BEligibleUsers: "B2BEligibleUsers",

  SellerCards: "SellerCards",
  Seller: "Seller",
  ProfileKYC: "ProfileKYC",
  OtherKYC: "OtherKYC",

  UserCards: "UserCards",
  InternalUser: "ErpUsers",
  TagonCustomer: "TagonCustomer",

  Horoscope: "Horoscope",

  DeliveryCards: "DeliveryCards",
  PendingRegistrations: "PendingRegistrations",
  RegisteredUsers: "RegisteredUsers",
  AssignedOrders: "AssignedOrders",
  DeliveryPrivacy: "DeliveryPrivacy",
  DeliveryFaq: "DeliveryFaq",
  DeliveryTerms: "DeliveryTerms",
  DeliveryChoice: "DeliveryChoice",
  UpdatedDocs: "UpdatedDocs",

  HelpCenterCards: "HelpCenterCards",
  FaqTitle: "FaqTitle",
  FaqContent: "FaqContent",
  HelplineForm: "HelplineForm",
  HelplineNumber: "HelplineNumber",

  PolicyCards: "PolicyCards",
  PolicyTitle: "PolicyTitle",
  PolicyContent: "PolicyContent",

  LogCards: "LogCards",
  ERPLogs: "ERPLogs",
  FcmResponse: "FcmResponse",
  PgResponse: "PgResponse",
  SmsResponse: "SmsResponse",
  PaymentLog: "PaymentLog",
  OrderLog: "OrderLog",
  PgWebhook: "PgWebhook",
  ContactUs: "ContactUs",
};

export const perms = {
  viewList: "VIEW_LIST",
  add: "ADD",
  view: "VIEW_LIST",
  edit: "EDIT",
  delete: "DELETE",

  resetPassword: "reset_password",
  verifyB2BProfile: "verifyB2BProfile",

  verifyProfileDeliveryBoy: "verify_profile_delivery_boy",
  generateBatch: "generateBatch",
  assignBatch: "assignBatch",
  editBatch: "editBatch",
  moveToList: "moveToList",

  verifySellerProfile: "EDIT",
  verifySellerOtherKYC: "EDIT",

  generateAirwaybillOrderConfirm: "generate_awb",
  cancelProductOrderConfirm: "cancelproduct",
  orderDenyOrderConfirm: "order_deny",
  changePickUpOrderConfirm: "pickup_address",
  regenerateAirwaybillOrderProcessed: "regenerate_awb",
  cancelAirwayBillOrderProcessed: "cancel_awb",
  orderDenyOrderProcessed: "order_deny",
  changePickUpOrderProcessed: "pickup_address",
  returnProductDelivered: "returnproduct",
  replaceProductDelivered: "replaceproduct",
  acceptCancelRequested: "status_change",
  generateAirwayBillCancelRequested: "generate_awb",
  initialRefundCancelled: "Refund",
  generateAirwayBillReturnRequested: "generate_awb",
  denyReturnRequested: "status_change",
  initialRefundReturned: "Refund",
  generateAirwayBillReplaceRequested: "generate_awb",
  denyReplaceRequested: "status_change",
  initialRefundOrderDenied: "Refund",
  initialRefundOrderFailed: "Refund",
};
