import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import { roles_url } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { moveUpToError } from "../../../utils/moveUpToError";
import Loading from "../../../components/Loading/Loading";
import "./new_roles.scss";
import { items as unfilteredItems } from "../../../data/routes";
import { ReactComponent as ArrowIcon } from "../../../images/arrow.svg";
import { modules, perms } from "../../../api/codes";
import { useForm } from "react-hook-form";
import { Input } from "../../../components/input";
import { InputToggle } from "../../../components/Inputs/Inputs";
import _ from "lodash";

const title = "Roles";
const module = modules.Role;
const backUrl = "/masters/roles";
const apiUrl = roles_url;

function dataToState(data) {
  const obj = {};
  data?.forEach((d) => {
    const permObj = {};
    d?.perms?.forEach((pm) => {
      permObj[pm] = true;
    });
    obj[d.code] = permObj;
  });
  return obj;
}

function stateToData(data) {
  return Object.keys(data)
    .filter((key) => data[key]?.[perms.viewList])
    .map((key) => ({
      code: key,
      perms: Object.keys(data[key]).filter((x) => data[key][x]),
    }));
}

const NewRoles = ({ edit, view }) => {
  const items = unfilteredItems.filter((item) => !item.noPerm);
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [originalData, setOriginalData] = useState();

  const methods = useForm();

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: { module: module },
          });
          const data = res.data;
          data.permissions = dataToState(data.permissions);
          data.force = false;
          setOriginalData(data);
          methods.reset(data);

          setIsGetting(false);
        } catch (err) {
          unknownError(err);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view, methods]);

  const [selectedModule, setSeletedModule] = useState(0);

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        const data = { ...methods.getValues() };
        data.permissions = stateToData(data.permissions);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", data, {
                headers: { module: module },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, data, {
                headers: { module: module },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <Input.Text
          methods={methods}
          name="user_role_name"
          label="Role name"
          placeholder="Role name"
          disabled={view}
          required
        />
        <Input.Text
          methods={methods}
          name="description"
          label="Description"
          placeholder="Description"
          disabled={view}
          required
          multiline
        />
      </div>
      <div style={{ padding: "10px" }} />
      <div style={{ padding: "10px" }} />
      <div className="role-wrapper">
        <div className="role-sidebar">
          {items.map((item, index) => (
            <div
              key={index}
              className={
                (index === selectedModule ? "active" : "") + " role-sidebar-btn"
              }
              onClick={() => setSeletedModule(index)}
            >
              {/* <div className="role-checkbox-wrapper">
                <InputCheckBox
                  value={checkMainModule(item, methods.watch("permissions"))}
                />
              </div> */}
              {item.title}
              {checkMainModule(item, methods.watch("permissions")) && (
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 96 96"
                  enableBackground="new 0 0 96 96"
                  style={{ backgroundColor: "#fff", borderRadius: "50%" }}
                >
                  <g>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="#6BBE66"
                      d="M48,0c26.51,0,48,21.49,48,48S74.51,96,48,96S0,74.51,0,48 S21.49,0,48,0L48,0z M26.764,49.277c0.644-3.734,4.906-5.813,8.269-3.79c0.305,0.182,0.596,0.398,0.867,0.646l0.026,0.025 c1.509,1.446,3.2,2.951,4.876,4.443l1.438,1.291l17.063-17.898c1.019-1.067,1.764-1.757,3.293-2.101 c5.235-1.155,8.916,5.244,5.206,9.155L46.536,63.366c-2.003,2.137-5.583,2.332-7.736,0.291c-1.234-1.146-2.576-2.312-3.933-3.489 c-2.35-2.042-4.747-4.125-6.701-6.187C26.993,52.809,26.487,50.89,26.764,49.277L26.764,49.277z"
                    />
                  </g>
                </svg>
              )}
            </div>
          ))}
        </div>
        <div className="role-right-side">
          {(items[selectedModule]?.childrens || [items[selectedModule]])?.map(
            (item, index) => {
              return item.module ? (
                <Accordian
                  key={`permissions.${item.module}.${perms.viewList}`}
                  head={
                    <div style={{ display: "flex" }}>
                      <Input.CheckBox
                        key={`permissions.${item.module}.${perms.viewList}`}
                        name={`permissions.${item.module}.${perms.viewList}`}
                        methods={methods}
                        label={item.title}
                        onChange={(v) => {
                          if (!v) {
                            const permsArray = getAvailablePerms(item);
                            permsArray.forEach((permItem) => {
                              methods.setValue(
                                `permissions.${item.module}.${permItem.code}`,
                                false
                              );
                            });
                          }
                        }}
                        labelColor="#000000"
                      />
                    </div>
                  }
                  noBody={getAvailablePerms(item).length === 0}
                  body={
                    methods.watch(
                      `permissions.${item.module}.${perms.viewList}`
                    ) ? (
                      <RenderPerms
                        key={`permissions.${item.module}`}
                        item={item}
                        methods={methods}
                      />
                    ) : (
                      <div className="no-perm-wrapper">
                        <div className="no-perm">
                          <RenderPerms
                            key={`permissions.${item.module}`}
                            item={item}
                            methods={methods}
                          />
                        </div>
                      </div>
                    )
                  }
                />
              ) : null;
            }
          )}
        </div>
      </div>
      <div className="p-10" />
      {edit && !_.isEqual(originalData, methods.getValues()) && (
        <InputToggle
          label="Force logout"
          disabled={view}
          value={methods.watch("force")}
          onChange={(v) => methods.setValue("force", v)}
        />
      )}
      <div className="p-10" />
      {!view && (
        <>
          <div style={{ padding: "10px" }} />
          <div style={{ padding: "10px" }} />
          <div className="actions">
            <button
              className="btn-red"
              type="button"
              onClick={() => {
                history.push(backUrl);
              }}
            >
              Cancel
            </button>
            <button
              className="btn"
              onClick={() => {
                moveUpToError();
              }}
              disabled={isSaving}
            >
              Save
            </button>
          </div>
        </>
      )}
      <div style={{ padding: "10px" }} />
    </form>
  );
};

function checkMainModule(item, permissions) {
  let ok = false;
  if (item.childrens) {
    item.childrens.forEach((x) => {
      if (
        permissions?.[x.module] !== undefined &&
        Object.keys(permissions?.[x.module])
          .map((key) => permissions?.[x.module][key])
          .filter((f) => f).length > 0
      ) {
        ok = true;
      }
    });
  } else {
    if (
      permissions?.[item.module] !== undefined &&
      Object.keys(permissions?.[item.module])
        .map((key) => permissions?.[item.module][key])
        .filter((f) => f).length > 0
    ) {
      ok = true;
    }
  }
  return ok;
}

function RenderPerms({ item, methods }) {
  const permsArray = getAvailablePerms(item);

  if (permsArray.length <= 0) {
    return false;
  }

  return (
    <div className="perm-grid" style={{ marginTop: "-10px" }}>
      {permsArray.map((perm, index) => {
        if (!item.module) return null;
        return (
          <div style={{ gridColumn: `span ${perm.span || 1}` }}>
            <Input.CheckBox
              key={index}
              name={`permissions.${item.module}.${perm.code}`}
              methods={methods}
              label={perm.name}
              disabled={
                !methods.watch(`permissions.${item.module}.${perms.viewList}`)
              }
            />
          </div>
        );
      })}
    </div>
  );
}

function getAvailablePerms(item) {
  if (item.module === modules.Order) {
    return [
      { code: perms.add, name: "Add" },
      { code: perms.edit, name: "Edit" },
      {
        code: perms.generateAirwaybillOrderConfirm,
        name: "Generate airwaybill",
      },
      { code: perms.cancelProductOrderConfirm, name: "Cancel product" },
      { code: perms.orderDenyOrderConfirm, name: "Order deny" },
      { code: perms.changePickUpOrderConfirm, name: "Change pick-up address" },
      {
        code: perms.regenerateAirwaybillOrderProcessed,
        name: "Re-generate airwaybill",
      },
      { code: perms.cancelAirwayBillOrderProcessed, name: "Cancel airwaybill" },
      { code: perms.returnProductDelivered, name: "Return product" },
      { code: perms.replaceProductDelivered, name: "Replace product" },
      { code: perms.initialRefundCancelled, name: "Initiat refund" },
      {
        code: perms.acceptCancelRequested,
        name: "Change status (accept cancel request, deny return and replace request)",
        span: 3,
      },
    ];
  }
  if (item.module === modules.InternalUser) {
    return [
      { code: perms.add, name: "Add" },
      { code: perms.edit, name: "Edit" },
      // { code: perms.view, name: "View" },
      { code: perms.delete, name: "Delete" },
      { code: perms.resetPassword, name: "Reset Password" },
    ];
  }
  if (item.module === modules.AssignedOrders) {
    return [
      { code: perms.generateBatch, name: "Generate batch" },
      { code: perms.assignBatch, name: "Assign batch" },
      { code: perms.editBatch, name: "Edit batch" },
      { code: perms.moveToList, name: "Move reschedule awb to awb list" },
    ];
  }
  if (item.module === modules.B2BEligibleUsers) {
    return [{ code: perms.add, name: "Add Credit" }];
  }
  if (item.module === modules.TagonCustomer) {
    return [
      // { code: perms.add, name: "Make a user b2b" }
    ];
  }
  if (item.module === modules.RegisteredUsers) {
    return [];
  }

  // Read only
  if (
    [
      modules.CategoryChart,
      modules.HelplineForm,
      modules.ERPLogs,
      modules.PaymentLog,
      modules.OrderLog,
      modules.FcmResponse,
      modules.PgResponse,
      modules.SmsResponse,
      modules.PgWebhook,
      modules.ContactUs,
    ].find((x) => x === item.module)
  ) {
    return [];
  }

  return [
    { code: perms.add, name: "Add" },
    { code: perms.edit, name: "Edit" },
    // { code: perms.view, name: "View" },
    { code: perms.delete, name: "Delete" },
  ];
}

const Accordian = ({ head, body, footer, noBody }) => {
  const [open] = useState(true);
  const height = 10000;

  return (
    <div className={"racc " + (open ? "open" : "")}>
      <div
        className={"racc-head " + (open ? "open" : "")}
        // onClick={() => setOpen(!open)}
      >
        <b>{head}</b>
        <ArrowIcon className={"racc-arrow " + (open ? "open" : "")} />
      </div>
      {!noBody && (
        <div
          className={"racc-body " + (open ? "open" : "")}
          style={{ maxHeight: open ? height : 0 }}
        >
          {body}
          {footer}
        </div>
      )}
    </div>
  );
};

export default NewRoles;
