import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputCheckBox,
  InputDate,
  InputImage,
  InputNumber,
  InputSelect,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../components/Inputs/Inputs";
import BackButton from "../../components/BackButton/BackButton";
import { api } from "../../api/api";
import objToFormdata from "../../utils/objToFormdata";
import { useToast } from "../../components/Toast";
import {
  brand_url,
  country_url,
  coupons_url,
  first_category_url,
  product_url,
  second_category_url,
  seller_url,
  third_category_url,
} from "../../api/urls";
import { idToname } from "../../utils/idToname";
import { nameToid } from "../../utils/nameToid";
import { urlTofile } from "../../utils/urlTofile";
import unknownError from "../../utils/unknownError";
import { APIDropdown } from "../../components/APIDropdown/APIDropdown";
import Loading from "../../components/Loading/Loading";
import { moveUpToError } from "../../utils/moveUpToError";

const initialState = {
  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  expiry: "",
  promocode: "",
  discount_type: "",
  discount_upto: "",
  min_txn: "",
  coupon_image: "",
  alpha_two_code: "",
  currency: "",
  currency_symbol: "",
  brand: "",
  seller: "",
  category_first: "",
  products: "",
  category_second: "",
  category_third: "",
  country: "",
};
const title = "Coupons";
const backUrl = "/coupons";
const apiUrl = coupons_url;

const linkedKeys = [
  "brand",
  "seller",
  "category_first",
  "category_second",
  "category_third",
  "products",
];

const NewCoupons = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [countrys, setCountrys] = useState();
  const [brands, setBrands] = useState();
  const [sellers, setSellers] = useState();
  const [firstCategorys, setFirstCategorys] = useState();
  const [secondCategorys, setSecondCategorys] = useState();
  const [thirdCategorys, setThirdCategorys] = useState();
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [linkData, setLinkData] = useState({});
  const setLinked = (key, value) => {
    return setLinkData({ [key]: value });
  };
  const isNotLinked = (key) => {
    if (linkData[key]) {
      return false;
    } else if (Object.keys(linkData).filter((x) => linkData[x]).length === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/");
          res.data.coupon_image = await urlTofile(res.data.coupon_image);
          linkedKeys.forEach((lk) => {
            if (res.data[lk]) {
              setLinked(lk, res.data[lk]);
              res.data[lk] = "";
            }
          });
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(country_url);
        setCountrys(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, []);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(brand_url);
        setBrands(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, []);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(seller_url);
        setSellers(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, []);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(first_category_url);
        setFirstCategorys(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, []);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(second_category_url);
        setSecondCategorys(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, []);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(third_category_url);
        setThirdCategorys(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, []);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        const newData = { ...data };
        try {
          if (edit && params.id) {
            api
              .patch(
                apiUrl + data.id + "/",
                objToFormdata({ ...newData, ...linkData })
              )
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, objToFormdata({ ...newData, ...linkData }))
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Code"
          placeholder="Code"
          disabled={view}
          value={data.code}
          error={errors["code"]}
          required
          onChange={(v) => set("code", v)}
        />
        <InputText
          label="Printable name"
          placeholder="Printable name"
          disabled={view}
          value={data.printable_name}
          error={errors["printable_name"]}
          required
          onChange={(v) => set("printable_name", v)}
        />
        <InputTextBox
          label="Description"
          placeholder="Description"
          disabled={view}
          value={data.description}
          error={errors["description"]}
          required
          onChange={(v) => set("description", v)}
        />
        <InputDate
          label="Expiry"
          placeholder="Expiry"
          disabled={view}
          value={data.expiry}
          error={errors["expiry"]}
          required
          type="datetime-local"
          onChange={(v) => set("expiry", v)}
        />
        <InputText
          label="Promo code"
          placeholder="Promo code"
          disabled={view}
          value={data.promocode}
          error={errors["promocode"]}
          required
          onChange={(v) => set("promocode", v)}
        />
        <InputSelect
          label="Discount type"
          placeholder="Discount type"
          required
          value={data.discount_type}
          options={["percent", "value"]}
          onChange={(v) => set("discount_type", v)}
          error={errors["discount_type"]}
        />
        <InputNumber
          label="Discount value"
          placeholder="Discount value"
          disabled={view}
          value={data.discount_upto}
          error={errors["discount_upto"]}
          required
          onChange={(v) => set("discount_upto", v)}
        />
        <InputText
          label="Minimum transaction"
          placeholder="Minimum transaction"
          required
          value={data.min_txn}
          onChange={(v) => set("min_txn", v)}
          error={errors["min_txn"]}
        />
        <InputImage
          label="Coupon image"
          placeholder="Coupon image"
          disabled={view}
          value={data.coupon_image}
          error={errors["coupon_image"]}
          onChange={(v) => set("coupon_image", v)}
        />
        <InputSelect
          label="Country"
          placeholder="Country"
          required
          value={idToname(data.country, countrys)}
          options={countrys ? countrys.map((d) => d.printable_name) : []}
          onChange={(v) => set("country", nameToid(v, countrys))}
          error={errors["country"]}
          disabled={view}
        />
        <InputSelect
          label="Brand"
          placeholder="Brand"
          value={idToname(linkData.brand, brands)}
          options={brands ? brands.map((d) => d.printable_name) : []}
          onChange={(v) => setLinked("brand", nameToid(v, brands))}
          error={errors["brand"]}
          disabled={view || isNotLinked("brand")}
        />
        <InputSelect
          label="Seller"
          placeholder="Seller"
          value={idToname(linkData.seller, sellers)}
          options={sellers ? sellers.map((d) => d.printable_name) : []}
          onChange={(v) => setLinked("seller", nameToid(v, sellers))}
          error={errors["seller"]}
          disabled={view || isNotLinked("seller")}
        />
        <InputSelect
          label="First category"
          placeholder="First category"
          value={idToname(linkData.category_first, firstCategorys)}
          options={
            firstCategorys ? firstCategorys.map((d) => d.printable_name) : []
          }
          onChange={(v) =>
            setLinked("category_first", nameToid(v, firstCategorys))
          }
          error={errors["category_first"]}
          disabled={view || isNotLinked("category_first")}
        />
        <InputSelect
          label="Second category"
          placeholder="Second category"
          value={idToname(linkData.category_second, secondCategorys)}
          options={
            secondCategorys ? secondCategorys.map((d) => d.printable_name) : []
          }
          onChange={(v) =>
            setLinked("category_second", nameToid(v, secondCategorys))
          }
          error={errors["category_second"]}
          disabled={view || isNotLinked("category_second")}
        />
        <InputSelect
          label="Third category"
          placeholder="Third category"
          value={idToname(linkData.category_third, thirdCategorys)}
          options={
            thirdCategorys ? thirdCategorys.map((d) => d.printable_name) : []
          }
          onChange={(v) =>
            setLinked("category_third", nameToid(v, thirdCategorys))
          }
          error={errors["category_third"]}
          disabled={view || isNotLinked("category_third")}
        />
        <APIDropdown
          label="Product"
          placeholder="Product"
          url={product_url}
          value={linkData.products}
          onChange={(v) => setLinked("products", v)}
          hasPagination
          error={errors["products"]}
          disabled={view || isNotLinked("products")}
        />
        <InputCheckBox
          label="Only usable using promo code"
          disabled={view}
          value={data.disable}
          error={errors["disable"]}
          onChange={(v) => set("disable", v)}
        />
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewCoupons;
