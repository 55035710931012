import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import moment from "moment";
import _ from "lodash";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import deepDiff from "deep-diff";

const LogDetails = ({ data: d }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={<div className="blue-link">View Details</div>}
      className="modal-window"
      style={{
        height: "90vh",
        width: "600px",
        maxWidth: "90vw",
      }}
    >
      <div
        className="close-btn"
        style={{ position: "absolute" }}
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div
        style={{
          height: "90vh",
          maxHeight: "90vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "20px",
            borderBottom: "1px solid #00000080",
            paddingBottom: "10px",
          }}
        >
          <div className="p-10" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <TitleSubtitle title={d.username} subTitle={d.email} />
            <TitleSubtitle
              title={
                moment(d.created_date).format("DD-MM-YYYY") +
                " " +
                moment(d.created_date).format("hh:mm:ss A")
              }
              subTitle={d.remote_ip_address}
              align="right"
            />
          </div>
          <div className="p-10" />
          <div
            style={{
              fontSize: "16px",
            }}
          >
            <b>{d.action}d</b> {d.module_lable} <b>{d?.created_data?.id}</b>
          </div>
        </div>
        <div style={{ overflow: "auto", flex: 1, padding: "20px" }}>
          <Diff data={d} />
        </div>
        <div style={{ padding: "3px" }} />
      </div>
    </Modal>
  );
};

// function Diff({ data: d }) {
//   const diffs =
//     d.action === "Create" || d.action === "Delete"
//       ? Object.keys(d.created_data).map((key) => ({
//           key: key,
//           from: d.created_data[key],
//           // to: d.update_data[key].new_data,
//         }))
//       : Object.keys(d.update_data).map((key) => ({
//           key: key,
//           from: d.update_data[key].old_data,
//           to: d.update_data[key].new_data,
//         }));

//   return (
//     <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
//       {diffs.map((diff, index) => {
//         // Don't show logs if these keys
//         const ignoreKeys = ["updated_at", "created_at", "created", "updated"];
//         if (ignoreKeys.find((ik) => diff.key === ik)) {
//           return null;
//         }

//         if (d.action === "Create") {
//           return (
//             <div
//               key={index}
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "0px",
//               }}
//             >
//               <div style={{ color: "#0000004D" }}>
//                 Added <b>{formatKey(diff.key)}</b>
//               </div>
//               <div>
//                 <b>{formatValue(diff.from)}</b>
//               </div>
//             </div>
//           );
//         }
//         if (d.action === "Delete") {
//           return (
//             <div
//               key={index}
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "0px",
//               }}
//             >
//               <div style={{ color: "#0000004D" }}>
//                 Deleted <b>{formatKey(diff.key)}</b>
//               </div>
//               <div>
//                 <b>{formatValue(diff.from)}</b>
//               </div>
//             </div>
//           );
//         }
//         return (
//           <div
//             key={index}
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               gap: "0px",
//             }}
//           >
//             <div style={{ color: "#0000004D" }}>
//               Updated <b>{formatKey(diff.key)}</b>
//             </div>
//             <div style={{ display: "flex", gap: "5px" }}>
//               Changed from <b>{formatValue(diff.from)}</b> to{" "}
//               <b>{formatValue(diff.to)}</b>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// }

function formatKey(key) {
  if (_.isString(key)) {
    return capitalizeFirstLetter(key?.replace("_", " "));
  }
  return key;
}

function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string.slice(1);
}

function formatValue(v) {
  if (_.isString(v) && v?.match(/\.(jpeg|jpg|gif|png)$/) != null) {
    return (
      <div style={{ marginTop: "3px", width: "min-content" }}>
        <DashboardImage src={v} height="100px" width="100px" />
      </div>
    );
  }
  if (_.isString(v) && isDate(v)) {
    return moment(v).format("DD-MM-YYYY");
  }
  return _.toString(v);
}

function isDate(v) {
  const formats = ["DD-MM-YYYY", "DD/MM/YYYY", moment.ISO_8601];
  return moment(v, formats, true).isValid();
}

function Diff({ data: d }) {
  const diffs = deepDiff(d.created_data, d.change_data);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {diffs?.map((diff, index) => {
        let UI = "";
        // Don't show logs if these keys
        const ignoreKeys = [
          "updated_at",
          "created_at",
          "created",
          "updated",
          "id",
        ];
        if (ignoreKeys.find((ik) => diff?.path?.find((p) => p === ik))) {
          return null;
        }
        if (_.isString(diff.lhs) && diff.lhs.startsWith("ERP")) {
          return null;
        }
        switch (diff.kind) {
          case "N": // New (added)
            UI = (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                }}
              >
                <div style={{ color: "#0000004D" }}>
                  Added <b>{formatKey(diff.path.pop())}</b>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <b>{formatValue(diff.rhs)}</b>
                </div>
              </div>
            );
            break;
          case "D": // Deleted
            UI = (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                }}
              >
                <div style={{ color: "#0000004D" }}>
                  Deleted <b>{formatKey(diff.path.pop())}</b>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <b>{formatValue(diff.lhs)}</b>
                </div>
              </div>
            );
            break;
          case "E": // Edited (changed)
            UI = (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
                }}
              >
                <div style={{ color: "#0000004D" }}>
                  Changed <b>{formatKey(diff.path.pop())}</b>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  From <b>{formatValue(diff.lhs)}</b> To{" "}
                  <b>{formatValue(diff.rhs)}</b>
                </div>
              </div>
            );
            break;
          case "A": // Array item changed
            UI = null;
            break;
          default:
            UI = null;
            break;
        }
        if (!UI) return null;
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0px",
            }}
          >
            {UI}
          </div>
        );
      })}
    </div>
  );
}

function TitleSubtitle({ title, subTitle, align = "left" }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        alignItems: align === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div>{title}</div>
      <div style={{ color: "#0000004D" }}>{subTitle}</div>
    </div>
  );
}

export default LogDetails;
