import { tagon_customer_url } from "../../../api/urls";
import { TextChip } from "../../../components/TextChip/TextChip";
import { Link } from "react-router-dom";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import BackButton from "../../../components/BackButton/BackButton";
import { Header, Icon, Input } from "semantic-ui-react";
import { useState } from "react";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { ReactComponent as UserIcon } from "../../../images/user.svg";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";

export function B2BProfileList() {
  const apiUrl = tagon_customer_url + "?bnpl=verify";
  const title = "B2B Verification";
  const module = modules.B2BVerification;
  const currentRoute = "b2b_verification";
  const columns = [
    {
      headerName: "Profile Image",
      field: "user",
      renderCell: (params) => (
        <DashboardImage
          height="50px"
          width="50px"
          circle
          src={params?.value?.profile_image}
        />
      ),
    },
    {
      headerName: "Name",
      field: "user",
      renderCell: (params) => params?.value?.full_name,
    },
    {
      headerName: "Mobile number",
      field: "user",
      renderCell: (params) => params?.value?.mobile_number,
    },
    {
      headerName: "Status",
      renderCell: () => <TextChip color="#F87A01" text="Pending" />,
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          options={(closer) => {
            return (
              <ProtectedElement module={module} perm={perms.edit}>
                <Link to={currentRoute + `/verify/${params?.row?.user?.id}`}>
                  <div
                    className="option"
                    onClick={() => {
                      closer();
                    }}
                  >
                    <div className="icon-btn purple-icon">
                      <UserIcon />
                    </div>
                    <div className="purple-title">Verify profile</div>
                  </div>
                </Link>
              </ProtectedElement>
            );
          }}
        />
      ),
    },
  ];

  const { data, pagination } = useFetchWithPagination(apiUrl);
  const { search, setSearch } = useState("");
  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      <TableComponent
        columns={columns}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </>
  );
}
