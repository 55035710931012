import { ReactComponent as gridIcon } from "../images/grid.svg";
import { ReactComponent as dbIcon } from "../images/database.svg";
import { ReactComponent as userIcon } from "../images/user.svg";
import { ReactComponent as boxIcon } from "../images/box.svg";
import { modules } from "../api/codes";

export const items = [
  {
    title: "Dashboard",
    icon: gridIcon,
    path: "/dashboard",
  },
  {
    title: "Masters",
    icon: gridIcon,
    path: "/masters",
    module: modules.MasterCards,
    childrens: [
      {
        title: "Order Status",
        icon: boxIcon,
        path: "/masters/order_status",
        module: modules.OrderStatus,
      },
      {
        title: "First Category",
        icon: boxIcon,
        path: "/masters/first_category",
        module: modules.FirstCategory,
      },
      {
        title: "Second Category",
        icon: boxIcon,
        path: "/masters/second_category",
        module: modules.SecondCategory,
      },
      {
        title: "Third Category",
        icon: boxIcon,
        path: "/masters/third_category",
        module: modules.ThirdCategory,
      },
      {
        title: "Cateogory Chart",
        icon: boxIcon,
        path: "/masters/category_chart",
        module: modules.CategoryChart,
      },
      {
        title: "Product Size",
        icon: dbIcon,
        path: "/masters/size",
        module: modules.ProductSize,
      },
      {
        title: "Product Color",
        icon: dbIcon,
        path: "/masters/color",
        module: modules.ProductColor,
      },
      {
        title: "Brand",
        icon: dbIcon,
        path: "/masters/brand",
        module: modules.Brand,
      },
      {
        title: "Property type",
        icon: dbIcon,
        path: "/masters/property_type",
        module: modules.PropertyType,
      },
      {
        title: "Property value",
        icon: dbIcon,
        path: "/masters/property_value",
        module: modules.PropertyValue,
      },
      {
        title: "HSN Code",
        icon: userIcon,
        path: "/masters/tax_code",
        module: modules.HsnCode,
      },
      {
        title: "HSN Percent",
        icon: userIcon,
        path: "/masters/tax_percent",
        module: modules.HsnPercent,
      },
      {
        title: "SAC Code",
        icon: userIcon,
        path: "/masters/sac_tax_code",
        module: modules.SacCode,
      },
      {
        title: "SAC Percent",
        icon: userIcon,
        path: "/masters/sac_tax_percent",
        module: modules.SacPercent,
      },
      {
        title: "Charge name",
        icon: dbIcon,
        path: "/masters/charge_name",
        module: modules.ChargeName,
      },
      {
        title: "Reels",
        icon: dbIcon,
        path: "/masters/reels",
        module: modules.Reel,
      },
      {
        title: "Product Questions",
        icon: dbIcon,
        path: "/masters/product_questions",
        module: modules.ProductQuestion,
      },
      {
        title: "Product Info",
        icon: dbIcon,
        path: "/masters/product_info",
        module: modules.ProductInfo,
      },
      // {
      //   title: "Price",
      //   icon: userIcon,
      //   childrens: [
      //     {
      //       title: "Price Range",
      //       path: "/masters/price_range",
      //     },
      //     {
      //       title: "Price Value",
      //       path: "/masters/price_value",
      //     },
      //   ],
      // },
      // {
      //   title: "Percentage",
      //   icon: userIcon,
      //   childrens: [
      //     {
      //       title: "Percentage Range",
      //       path: "/masters/percentage_range",
      //     },
      //     {
      //       title: "Percentage Value",
      //       path: "/masters/percentage_value",
      //     },
      //   ],
      // },
      // {
      //   title: "Filter",
      //   icon: userIcon,
      //   childrens: [
      //     {
      //       title: "Filter Name",
      //       path: "/masters/filter_name",
      //     },
      //     {
      //       title: "Filter Value",
      //       path: "/masters/filter_value",
      //     },
      //   ],
      // },
      // {
      //   title: "Filters",
      //   icon: dbIcon,
      //   path: "/masters/filter_category",
      // },
      {
        title: "Sort List",
        icon: dbIcon,
        path: "/masters/sort_list",
        module: modules.SortList,
      },
      // {
      //   title: "IAN Type",
      //   icon: dbIcon,
      //   path: "/masters/ian_type",
      // },
      {
        title: "Logistics Partner",
        icon: boxIcon,
        path: "/masters/logistics_partner",
        module: modules.LogisticPartner,
      },
      {
        title: "Continents",
        icon: dbIcon,
        path: "/masters/continents",
        module: modules.Continent,
      },
      {
        title: "Country",
        icon: dbIcon,
        path: "/masters/country",
        module: modules.Country,
      },
      {
        title: "Regions",
        icon: dbIcon,
        path: "/masters/regions",
        module: modules.Region,
      },
      {
        title: "State",
        icon: dbIcon,
        path: "/masters/state",
        module: modules.State,
      },
      {
        title: "District",
        icon: dbIcon,
        path: "/masters/district",
        module: modules.District,
      },
      {
        title: "Taluka",
        icon: dbIcon,
        path: "/masters/taluka",
        module: modules.Taluka,
      },
      {
        title: "City",
        icon: dbIcon,
        path: "/masters/city",
        module: modules.City,
      },
      {
        title: "Village",
        icon: dbIcon,
        path: "/masters/village",
        module: modules.Village,
      },
      {
        title: "Pincode",
        icon: dbIcon,
        path: "/masters/pincode",
        module: modules.Pincode,
      },
      {
        title: "Area",
        icon: dbIcon,
        path: "/masters/area",
        module: modules.Area,
      },
      {
        title: "Annual Turnover",
        icon: dbIcon,
        path: "/masters/annual_turnover",
        module: modules.AnnualTurnover,
      },
      {
        title: "Business Type",
        icon: dbIcon,
        path: "/masters/business_type",
        module: modules.BusinessType,
      },
      {
        title: "Organisation Type",
        icon: dbIcon,
        path: "/masters/organisation_type",
        module: modules.OrganisationType,
      },
      {
        title: "Facility Type",
        icon: dbIcon,
        path: "/masters/facility_type",
        module: modules.FacilityType,
      },
      {
        title: "Document Type",
        icon: dbIcon,
        path: "/masters/kyc_document",
        module: modules.DocumentType,
      },
      {
        title: "Certificate Type",
        icon: dbIcon,
        path: "/masters/kyc_certificate",
        module: modules.CertificateType,
      },
      {
        title: "Roles",
        icon: dbIcon,
        path: "/masters/roles",
        module: modules.Role,
      },
      // {
      //   title: "Delivery Boy Document Type",
      //   icon: dbIcon,
      //   path: "/masters/pls_kyc_document",
      // },
      // {
      //   title: "Delivery Boy Certificate Type",
      //   icon: dbIcon,
      //   path: "/masters/pls_kyc_certificate",
      // },
      {
        title: "Size Chart Column",
        icon: dbIcon,
        path: "/masters/size_chart_column",
        module: modules.SizeChartColumn,
      },
      {
        title: "Marital Status",
        icon: dbIcon,
        path: "/masters/marital_status",
        module: modules.MartialStatus,
      },
      {
        title: "Title",
        icon: dbIcon,
        path: "/masters/title",
        module: modules.Title,
      },
      {
        title: "Payment Gateway",
        icon: dbIcon,
        path: "/masters/payment_gateway",
        module: modules.PaymentGateway,
      },
      {
        title: "Notifications",
        icon: dbIcon,
        path: "/masters/notifications",
        module: modules.Notification,
      },
      {
        title: "Widget Title",
        icon: dbIcon,
        path: "/masters/widget_title",
        module: modules.WidgetTitle,
      },
      {
        title: "Configuration",
        icon: dbIcon,
        path: "/masters/configuration",
        module: modules.Configuration,
      },
      {
        title: "Branch",
        icon: dbIcon,
        path: "/masters/branch",
        module: modules.Branch,
      },
      {
        title: "Department",
        icon: dbIcon,
        path: "/masters/department",
        module: modules.Department,
      },
      {
        title: "Designation",
        icon: dbIcon,
        path: "/masters/designation",
        module: modules.Designation,
      },
      // {
      //   title: "Employee Grade",
      //   icon: dbIcon,
      //   path: "/masters/grade",
      //   module: modules.Granding,
      // },
      // {
      //   title: "Profile",
      //   icon: dbIcon,
      //   path: "/masters/profile",
      //   module: modules.Profile,
      // },
      // {
      //   title: "B2B Verification Fields",
      //   icon: dbIcon,
      //   path: "/masters/b2b_verification_fields",
      //   module: modules.B2BVerificationFeilds,
      // },
    ],
  },
  {
    title: "Orders",
    icon: gridIcon,
    path: "/orders",
    module: modules.Order,
  },
  {
    title: "Products",
    icon: dbIcon,
    path: "/products",
    module: modules.Product,
  },
  {
    title: "Global Store",
    icon: userIcon,
    path: "/global_store",
    module: modules.GlobalCards,
    childrens: [
      {
        title: "Products",
        icon: dbIcon,
        path: "/global_store/products",
        module: modules.GlobalProduct,
      },
      {
        title: "Sellers",
        icon: dbIcon,
        path: "/global_store/sellers",
        module: modules.GlobalSeller,
      },
      {
        title: "Luxury Brand",
        icon: dbIcon,
        path: "/global_store/luxury_brand",
        module: modules.GlobalLuxuryBrand,
      },
      {
        title: "Premium Brand",
        icon: dbIcon,
        path: "/global_store/premium_brand",
        module: modules.GlobalPremiumBrand,
      },
      {
        title: "First Category",
        icon: boxIcon,
        path: "/global_store/first_category",
        module: modules.GlobalCategoryOne,
      },
      {
        title: "Second Category",
        icon: boxIcon,
        path: "/global_store/second_category",
        module: modules.GlobalCategoryTwo,
      },
      {
        title: "Third Category",
        icon: boxIcon,
        path: "/global_store/third_category",
        module: modules.GlobalCategoryThree,
      },
    ],
  },
  // {
  //   title: "B2B Store",
  //   icon: userIcon,
  //   path: "/b2b_store",
  //   module: modules.B2BCards,
  //   childrens: [
  //     {
  //       title: "Eligible Users",
  //       icon: dbIcon,
  //       path: "/b2b_store/eligible_users",
  //       module: modules.B2BEligibleUsers,
  //     },
  //     {
  //       title: "B2B Verification",
  //       icon: dbIcon,
  //       path: "/b2b_store/b2b_verification",
  //       module: modules.B2BVerification,
  //     },
  //     {
  //       title: "Products",
  //       icon: dbIcon,
  //       path: "/b2b_store/products",
  //       module: modules.B2BProduct,
  //     },
  //     {
  //       title: "First Category",
  //       icon: dbIcon,
  //       path: "/b2b_store/first_category",
  //       module: modules.B2BCategoryOne,
  //     },
  //     {
  //       title: "Second Category",
  //       icon: dbIcon,
  //       path: "/b2b_store/second_category",
  //       module: modules.B2BCategoryTwo,
  //     },
  //     {
  //       title: "Third Category",
  //       icon: dbIcon,
  //       path: "/b2b_store/third_category",
  //       module: modules.B2BCategoryThree,
  //     },
  //   ],
  // },
  {
    title: "Users",
    icon: userIcon,
    path: "/users",
    module: modules.UserCards,
    childrens: [
      {
        title: "Internal users",
        icon: dbIcon,
        path: "/users/internal_users",
        module: modules.InternalUser,
      },
      {
        title: "Tagon customers",
        icon: dbIcon,
        path: "/users/tagon_customers",
        module: modules.TagonCustomer,
      },
      // {
      //   title: "B2B Verification",
      //   icon: dbIcon,
      //   path: "/users/b2b_verification",
      //   module: modules.B2BVerification,
      // },
    ],
  },
  {
    title: "Sellers",
    icon: dbIcon,
    path: "/sellers",
    module: modules.SellerCards,
    childrens: [
      {
        title: "Sellers",
        icon: dbIcon,
        path: "/sellers/sellers",
        module: modules.Seller,
      },
      // {
      //   title: "Profile KYC",
      //   icon: dbIcon,
      //   path: "/sellers/profile_kyc",
      //   module: modules.ProfileKYC,
      // },
      // {
      //   title: "Other KYC",
      //   icon: dbIcon,
      //   path: "/sellers/other_kyc",
      //   module: modules.OtherKYC,
      // },
    ],
  },
  {
    title: "Delivery",
    icon: dbIcon,
    path: "/logistics",
    module: modules.DeliveryCards,
    childrens: [
      {
        title: "Assigned Orders",
        icon: dbIcon,
        path: "/logistics/assigned_orders",
        module: modules.AssignedOrders,
      },
      {
        title: "Registered Users",
        icon: dbIcon,
        path: "/logistics/pls",
        module: modules.RegisteredUsers,
      },
      {
        title: "Pending Registrations",
        icon: dbIcon,
        path: "/logistics/pending_registrations",
        module: modules.PendingRegistrations,
      },
      {
        title: "Privacy Policy",
        icon: dbIcon,
        path: "/logistics/pls_privacy",
        module: modules.DeliveryPrivacy,
      },
      {
        title: "FAQ",
        icon: dbIcon,
        path: "/logistics/pls_faq",
        module: modules.DeliveryFaq,
      },
      {
        title: "Delivered To Options",
        icon: dbIcon,
        path: "/logistics/delivered_to",
        module: modules.DeliveryChoice,
      },
      {
        title: "Terms & Conditions",
        icon: dbIcon,
        path: "/logistics/pls_terms",
        module: modules.DeliveryTerms,
      },
    ],
  },
  {
    title: "Coupons",
    icon: gridIcon,
    path: "/coupons",
    module: modules.Coupon,
  },
  {
    title: "Advertisements",
    icon: gridIcon,
    path: "/advertisements",
    module: modules.Advertisement,
  },
  {
    title: "Policy",
    icon: gridIcon,
    path: "/policy",
    module: modules.PolicyCards,
    childrens: [
      {
        title: "Policy Title",
        icon: userIcon,
        path: "/policy/policy_title",
        module: modules.PolicyTitle,
      },
      {
        title: "Policy Content",
        icon: userIcon,
        path: "/policy/policy_content",
        module: modules.PolicyContent,
      },
    ],
  },
  {
    title: "Help center",
    icon: gridIcon,
    path: "/help_center",
    module: modules.HelpCenterCards,
    childrens: [
      {
        title: "Customer Complaints",
        icon: userIcon,
        path: "/help_center/helpline_forms",
        module: modules.HelplineForm,
      },
      {
        title: "Helpline number",
        icon: userIcon,
        path: "/help_center/helpline_number",
        module: modules.HelplineNumber,
      },
      {
        title: "FAQ's title",
        icon: userIcon,
        path: "/help_center/faqs_title",
        module: modules.FaqTitle,
      },
      {
        title: "FAQ's content",
        icon: userIcon,
        path: "/help_center/faqs_content",
        module: modules.FaqContent,
      },
    ],
  },
  {
    title: "Horoscope",
    icon: gridIcon,
    path: "/horoscope",
    module: modules.Horoscope,
  },
  {
    title: "Logs",
    icon: gridIcon,
    path: "/logs",
    module: modules.LogCards,
    childrens: [
      // {
      //   title: "ERP logs",
      //   icon: userIcon,
      //   path: "/logs/erp_logs",
      //   module: modules.ERPLogs,
      // },
      {
        title: "Payment logs",
        icon: userIcon,
        path: "/logs/payment_logs",
        module: modules.PaymentLog,
      },
      {
        title: "Order logs",
        icon: userIcon,
        path: "/logs/order_logs",
        module: modules.OrderLog,
      },
      {
        title: "FCM Response",
        icon: userIcon,
        path: "/logs/fcm_response",
        module: modules.FcmResponse,
      },
      {
        title: "PG Response",
        icon: userIcon,
        path: "/logs/pg_response",
        module: modules.PgResponse,
      },
      {
        title: "SMS Response",
        icon: userIcon,
        path: "/logs/sms_response",
        module: modules.SmsResponse,
      },
      {
        title: "PG Webhook",
        icon: userIcon,
        path: "/logs/pg_webhook",
        module: modules.PgWebhook,
      },
      {
        title: "Contact Us",
        icon: userIcon,
        path: "/logs/contact_us",
        module: modules.ContactUs,
      },
    ],
  },
];
