import { Header } from "semantic-ui-react";
import { tagon_customer_url } from "../../../api/urls";
import BackButton from "../../../components/BackButton/BackButton";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../utils/useFetch";
import { TitleLine } from "../../../components/lineTitle";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import Loading from "../../../components/Loading/Loading";
import { useModal } from "../../../components/Modaler";
import { useState } from "react";
import { api } from "../../../api/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import moment from "moment";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import {
  userAddressFormatter,
  userNameFormatter,
} from "../../../utils/addresssFormatter";

export function VerifyB2BProfile() {
  const title = "B2B Verification";
  const apiUrl = tagon_customer_url;
  const backUrl = "/b2b_store/b2b_verification";
  const params = useParams();
  const id = params.id;
  const history = useHistory();
  const toast = useToast();

  const data = useFetch(apiUrl + id + "/");

  const [saving, setSaving] = useState(false);

  if (data.loading || !data.data) {
    return <Loading />;
  }

  return (
    <div>
      <BackButton href={backUrl} />
      <Header>{title}</Header>
      <div className="p-10" />
      <PersonalInfo data={data} />
      <div className="p-10" />
      <UploadedDocuments data={data} />
      <div className="p-10" />
      <div className="p-10" />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <div style={{ flex: 1 }} />
        <button
          className="btn btn-red"
          disabled={saving}
          onClick={async () => {
            setSaving(true);
            try {
              await api.patch(tagon_customer_url + id + "/", {
                Declined: true,
              });
              history.push(backUrl);
              toast.open("Application rejected", "success");
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setSaving(false);
          }}
        >
          Reject
        </button>
        <button
          className="btn"
          disabled={saving}
          onClick={async () => {
            setSaving(true);
            try {
              await api.patch(tagon_customer_url + id + "/", {
                is_verified: true,
              });
              history.push(backUrl);
              toast.open("Application accepted", "success");
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setSaving(false);
          }}
        >
          Accept
        </button>
      </div>
      <div className="p-10" />
    </div>
  );
}

function UploadedDocuments({ data }) {
  const moduler = useModal();
  return (
    <>
      <TitleLine right>Uploaded documents</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Document name",
            field: "doc_name",
          },
          {
            headerName: "Doc no.",
            field: "doc_number",
          },
          {
            headerName: "Document expiry",
            field: "doc_expiry",
            renderCell: (params) =>
              params.value ? moment(params.value).format("DD-MM-YYYY") : "-",
          },
          {
            headerName: "File type",
            field: "doc_file",
            renderCell: (params) => params?.value?.split(".").pop(),
          },
          {
            headerName: "Action",
            field: "doc_file",
            renderCell: (params) => (
              <div
                className="blue-link"
                onClick={() => {
                  moduler.opener(
                    <DocumentVerifyModal
                      fileLink={params.value}
                      onSave={() => moduler.closer()}
                    />,
                    true
                  );
                }}
              >
                View
              </div>
            ),
          },
        ]}
        rows={data?.data?.docs}
        loading={data.loading}
      />
    </>
  );
}

function DocumentVerifyModal({ fileLink, onSave }) {
  function checkURLIsImage(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ width: "500px", height: "300px" }}>
        {checkURLIsImage(fileLink) ? (
          <DashboardImage src={fileLink} width="500px" height="300px" />
        ) : (
          <object data={fileLink} width="500px" height="300px">
            <p>
              unable to display this file, click
              <a href={fileLink} target="_blank" rel="noreferrer">
                here
              </a>
              open it.
            </p>
          </object>
        )}
      </div>
      <div className="p-10" />
      <div className="p-10" />
    </div>
  );
}

function PersonalInfo({ data }) {
  return (
    <>
      <TitleLine right>Personal Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Image",
            renderCell: (params) => (
              <DashboardImage
                width="50px"
                height="50px"
                src={params?.row?.user?.profile_image}
                circle
              />
            ),
          },
          {
            headerName: "Name",
            renderCell: (params) => `${userNameFormatter(params?.row?.user)}`,
          },
          {
            headerName: "Date of Birth",
            renderCell: (params) =>
              params?.row?.user?.birthday
                ? moment(params?.row?.user?.birthday).format("DD-MM-YYYY")
                : "-",
          },
          {
            headerName: "Contact",
            renderCell: (params) => `${params?.row?.user?.mobile_number}`,
          },
          {
            headerName: "Address",
            renderCell: (params) => (
              <div className="wrap" style={{ maxWidth: "250px" }}>
                {userAddressFormatter(params?.row?.add?.[0])}
              </div>
            ),
          },
        ]}
        rows={[{ ...data?.data }]}
        loading={data.loading}
      />
    </>
  );
}
