export const base_url = process.env.REACT_APP_API;
export const refresh_token_url = "/erp_refresh/";
export const user_login_url = "/erp_user_login/";
export const user_logout_url = "/tagon_erp/erp_logout/";
export const update_password_url = "/tagon_erp/upd_tmp_pswd/";

export const order_status_url = "/tagon_erp/erp_action_status/";
export const order_list_url = "/tagon_erp/erp_orders/";
export const inventory_list_url = "/tagon_erp/inv_location/";

export const product_url = "/tagon_erp/erp_products/";
export const product_add_url = "/tagon_erp/localerpproductsadd/";
export const size_chart_column_url = "/tagon_erp/erp_column_label/";
export const category_chart_url = "/tagon_erp/catgry/";
export const first_category_url = "/tagon_erp/localcategory1/";
export const second_category_url = "/tagon_erp/localcategory2/";
export const third_category_url = "/tagon_erp/localcategory3/";
export const size_url = "/tagon_erp/erp_size_label/";
export const color_url = "/tagon_erp/erp_Hex_Color/";
export const product_info_url = "/tagon_erp/erp_product_info/";
export const product_property_type_url = "/tagon_erp/erp_property_type/";
export const product_property_value_url = "/tagon_erp/erp_property_value/";
export const brand_url = "/tagon_erp/erpbrand/";
export const reels_url = "/tagon_erp/erp_prod_reel/";
export const tax_code_url = "/tagon_erp/hsnerptaxcode/";
export const tax_percent_url = "/tagon_erp/hsnerptaxprcnt/";
export const sac_tax_code_url = "/tagon_erp/sacerptaxcode/";
export const sac_tax_percent_url = "/tagon_erp/sacerptaxprcnt/";
export const charges_url = "/tagon_erp/erp_product_charges_name/";
export const product_questions_url = "/tagon_erp/erp_cat_quest/";
export const price_range_url = "/tagon_erp/erp_price_range/";
export const price_value_url = "/tagon_erp/erp_price_value/";
export const percentage_range_url = "/tagon_erp/erp_disc_prcnt_range/";
export const percentage_value_url = "/tagon_erp/erp_disc_prcnt/";
export const filter_name_url = "/tagon_erp/erp_filters/";
export const filter_value_url = "/tagon_erp/erp_filter_value/";
export const sort_list_url = "/tagon_erp/erp_prod_srt/";
export const filter_category_url = "/tagon_erp/erp_filter_cat/";
export const roles_url = "/tagon_erp/erp_roles/";

export const bfirst_category_url = "/tagon_erp/B2Bcategory1/";
export const bproduct_add_url = "/tagon_erp/B2Berpproductsadd/";
export const bsecond_category_url = "/tagon_erp/B2Bcategory2/";
export const bthird_category_url = "/tagon_erp/B2Bcategory3/";
export const bseller_url = "/tagon_erp/localerpsllr/";
export const bbrand_url = "/tagon_erp/erpbrand/";
export const bpbrand_url = "/tagon_erp/erpbrandglobalpremium/";
export const bhbrand_url = "/tagon_erp/erpbrandglobalhot/";
export const b2b_form_feilds_url = "/tagon_erp/b2bdocs/";
export const b2b_documents_url = "/tagon_erp/docdata/";

export const gfirst_category_url = "/tagon_erp/globalcategory1/";
export const gproduct_add_url = "/tagon_erp/globalerpproductsadd/";
export const gsecond_category_url = "/tagon_erp/globalcategory2/";
export const gthird_category_url = "/tagon_erp/globalcategory3/";
export const gseller_url = "/tagon_erp/globalerpsllr/";
export const gbrand_url = "/tagon_erp/erpbrand/";
export const gpbrand_url = "/tagon_erp/erpbrandglobalpremium/";
export const ghbrand_url = "/tagon_erp/erpbrandglobalhot/";

export const tagon_customer_url = "/tagon_erp/app_users/";
export const internal_users_url = "/tagon_erp/erp_user/";
export const register_url = "/erp_register_viewset/";
export const branch_url = "/tagon_erp/branch/";
export const designation_url = "/tagon_erp/desgn/";
export const grade_url = "/tagon_erp/emplgrade/";
export const department_url = "/tagon_erp/departmnt/";
export const profile_url = "/tagon_erp/profile/";

export const country_url = "/tagon_erp/erp_country/";
export const village_url = "/tagon_erp/erp_village/";
export const region_url = "/tagon_erp/erp_Region/";
export const district_url = "/tagon_erp/erp_District/";
export const taluka_url = "/tagon_erp/erp_Taluka/";
export const city_url = "/tagon_erp/erp_city/";
export const state_url = "/tagon_erp/erp_state/";
export const continent_url = "/tagon_erp/erp_continent/";
export const pincode_url = "/tagon_erp/erp_pincode/";
export const area_url = "/tagon_erp/erp_area/";
export const marital_status_url = "/tagon_erp/erp_Marital_Status/";
export const title_url = "/tagon_erp/erp_Title_Master/";

export const payment_gateway_url = "/tagon_erp/erp_payment_gateway/";
export const pg_webhook_url = "/tagon_erp/erp_pg_webhook/";
export const fcm_response_url = "/fcm_response";
export const pg_response_url = "/pg_response";
export const sms_response_url = "/sms_response";

export const policy_content_url = "/tagon_erp/erp_legal_cntnt/";
export const policy_title_url = "/tagon_erp/erp_legaltitle/";

export const helpline_forms_url = "/tagon_erp/erp_helpform_product/";
export const helpline_number_url = "/tagon_erp/erp_help_number/";
export const faq_title_url = "/tagon_erp/erp_faqtitle/";
export const faq_content_url = "/tagon_erp/erp_faq_cntnt/";

export const seller_url = "/tagon_erp/localerpsllr/";
export const seller_address = "/tagon_erp/erp_sllr_add/";
export const seller_bank = "/tagon_erp/erp_sllr_bank/";
export const seller_facility = "/tagon_erp/erp_sllr_faclty/";
export const seller_docs = "/tagon_erp/erp_sllr_docs/";
export const seller_business_type = "/tagon_erp/erp_bizz_type/";
export const seller_docs_master = "/tagon_erp/erp_sllr_docs_list/";
export const seller_organisation_type = "/tagon_erp/erp_orgn_type/";
export const seller_annual_income = "/tagon_erp/erp_ann_income/";

export const coupons_url = "/tagon_erp/erp_coupons/";

export const wallet_url = "/tagon_erp/erp_walletmoney/";
export const wallet_log_url = "/tagon_erp/erp_walletledger/";
export const points_url = "/tagon_erp/erp_tagonpoints/";
export const points_log_url = "/tagon_erp/erp_tagoncoinledger/";

export const advertisements_url = "/tagon_erp/erp_advt/";

export const configuration_url = "/tagon_erp/erp_config/";
export const widget_title_url = "/tagon_erp/erp_widgettitle/";

export const contact_us_url = "/tagon_erp/erp_cntctus/";

export const order_log_url = "/tagon_erp/erp_orderlogs/";
export const payment_log_url = "/tagon_erp/erp_paymentlogs/";
export const erp_logs_url = "/ticket/erp_logs";

export const bank_account_url = "/tagon_erp/app_user_bank/";

export const horoscope_title_url = "/tagon_erp/erp_horoscpe_nme/";
export const horoscope_des_url = "/tagon_erp/erppromotionnotification";
export const notification_url = "/tagon_erp/erphoroscopenotification";

export const logistics_parter_url = "/tagon_erp/erp_deliv_prtnrs/";
export const logistics_parter_hub_url = "/tagon_erp/erp_deliv_prtnrs_address/";

export const pls_url = "/tagon_erp/erp_deliv_boylist/";
export const pls_bank_url = "/tagon_erp/erp_deliv_boy_bank/";
export const pls_add_url = "/tagon_erp/erp_deliv_boy_address/";
export const pls_docs_url = "/tagon_erp/erp_deliv_boy_docmnts/";
export const pls_docs_master_url = "/tagon_erp/erp_deliv_boy_docs/";
export const delivery_boy_temp_url = "/tagon_erp/delivtemp/";
export const license_type_url = "/tagon_erp/license/";
export const delivery_partner_url = "/tagon_erp/erp_deliv_prtnrs/";
export const awb_list_url = "/tagon_erp/awblist/";
export const batch_list_url = "/tagon_erp/batchlist/";
export const assign_batch_url = "/tagon_erp/delvbatch/";
export const delivery_boy_list_url = "/tagon_erp/erp_deliv_userlist/";
export const pls_faq_url = "/tagon_erp/delfaq/";
export const pls_terms_url = "/tagon_erp/delvtnc/";
export const pls_privacy_url = "/tagon_erp/delprivacy/";
export const pls_choice_url = "/tagon_erp/delchoice/";
export const sr_partner_list_url = "/tagon_erp/courierlist/";
export const sr_rate_list_url = "/tagon_erp/ratelist/";
